<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BModal,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],
      detailModal: false,
      detailItem: null,
      filterUsername: '',
      filterBrandname: '',
      filterModelname: '',
      filterMarketprice: '',
    }
  },
  watch: {
    filterUsername: {
      handler() {
        this.filterNow()
      },
    },
    filterBrandname: {
      handler() {
        this.filterNow()
      },
    },
    filterModelname: {
      handler() {
        this.filterNow()
      },
    },
    filterMarketprice: {
      handler() {
        this.filterNow()
      },
    },
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {
      // Clear Variables..
      this.detailModal = false

      // Get List
      await this.$http.get('/market/list?offset=0&size=15&type=1')
        .then(res => {
          this.pageArrayStock = this.pageArray = res.data
        }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.user.userUsername.toLocaleLowerCase().includes(this.filterUsername.toLocaleLowerCase()) && x.brand.brandName.toLocaleLowerCase().includes(this.filterBrandname.toLocaleLowerCase()) && x.carModel.modelName.toLocaleLowerCase().includes(this.filterModelname.toLocaleLowerCase()) && x.marketItemPrice.toString().includes(this.filterMarketprice.toLocaleLowerCase()))
    },
    async detailMethod(item) {
      this.detailItem = await item
      this.detailModal = true
    },
  },
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card">
      <a class="tb-page-title">Registered Users ({{ pageArrayStock.length }})</a>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%">
              <input type="text" class="form-control" placeholder="#" disabled readonly>
            </th>
            <th style="width: 20%"><input v-model="filterUsername" type="text" class="form-control"
                placeholder="User Name"></th>
            <th style="width: 20%"><input v-model="filterBrandname" type="text" class="form-control"
                placeholder="Brand Name"></th>
            <th style="width: 20%"><input v-model="filterModelname" type="text" class="form-control"
                placeholder="Model Name"></th>
            <th style="width: 20%"><input v-model="filterMarketprice" type="text" class="form-control"
                placeholder="Market Price" readonly></th>
            <th style="width: 15%">
              <input type="text" class="form-control" placeholder="Options" readonly>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <td><a>{{ item.user.userUsername }}</a></td>
            <td><a>{{ item.brand.brandName }}</a></td>
            <td><a>{{ item.carModel.modelName }}</a></td>
            <td><a>{{ item.marketItemPrice }}</a></td>
            <td>
              <button class="tb-button bg-success" @click="detailMethod(item)">
                <span class="text-white">Detail</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal v-if="detailItem" v-model="detailModal" size="lg" hide-header hide-footer centered>
      <div class="row col-12 align-items-start justify-content-center tb-border-radius-0-5-rem mx-auto p-2 bg-white">
        <div class="col-12 col-lg-6 mt-2">
          <div class="col-12 text-center">
            <a class="tb-sub-title">User Details</a>
            <hr>
          </div>
          <table style="width:100%">
            <tr>
              <th>User Full Name:</th>
              <td>{{ detailItem.user.userFirstname }} {{ detailItem.user.userLastname }}</td>
            </tr>
            <tr>
              <th>User Name:</th>
              <td>{{ detailItem.user.userUsername }}</td>
            </tr>
            <tr>
              <th>User Language:</th>
              <td>{{ detailItem.user.userLanguage }}</td>
            </tr>
            <tr>
              <th>User Email:</th>
              <td>{{ detailItem.user.userEmail }}</td>
            </tr>
          </table>
        </div>
        <div class="col-12 col-lg-6 mt-2">
          <div class="col-12 text-center">
            <a class="tb-sub-title">Car Details</a>
            <hr>
          </div>
          <table style="width:100%">
            <tr>
              <th>Brand Name:</th>
              <td>{{ detailItem.brand.brandName }}</td>
            </tr>
            <tr>
              <th>Model Name:</th>
              <td>{{ detailItem.carModel.modelName }}</td>
            </tr>
            <tr>
              <th>Car Acceleration:</th>
              <td>{{ detailItem.car.carAcceleration }}</td>
            </tr>
            <tr>
              <th>Car Brake:</th>
              <td>{{ detailItem.car.carBrake }}</td>
            </tr>
            <tr>
              <th>Car Fuel:</th>
              <td>{{ detailItem.car.carFuel }}</td>
            </tr>
            <tr>
              <th>Car Grip:</th>
              <td>{{ detailItem.car.carGrip }}</td>
            </tr>
            <tr>
              <th>Car Health:</th>
              <td>{{ detailItem.car.carHealth }}</td>
            </tr>
            <tr>
              <th>Car Kilometer:</th>
              <td>{{ detailItem.car.carKilometer }}</td>
            </tr>
            <tr>
              <th>Car Top Speed:</th>
              <td>{{ detailItem.car.carTopSpeed}}</td>
            </tr>
          </table>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<style scoped>
th,
td {
  padding: 10px;
}

tr:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}

tr:nth-child(even) {
  background-color: rgb(228, 228, 228);
}
</style>
